import { render, staticRenderFns } from "./aboutUs.vue?vue&type=template&id=6217c643&scoped=true&"
import script from "./aboutUs.vue?vue&type=script&lang=js&"
export * from "./aboutUs.vue?vue&type=script&lang=js&"
import style0 from "./aboutUs.vue?vue&type=style&index=0&id=6217c643&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6217c643",
  null
  
)

export default component.exports