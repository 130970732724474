<template>
  <div>
    <shop-head currentPageName="AGREEMENT"></shop-head>
    <div class="container">
      <div class="img-box">
        <img src="../../assets/images/company.jpg" alt="">
        <img src="../../assets/images/qa.jpg" alt="">
      </div>
      <div class="text bottom">Guanghzou BBmart was founded in 2005 in Guangzhou, China.</div>
      <div class="text bottom">After 16 years of development, it has been focusing on the research and development of engine and chassis parts for Mercedes-Benz, BMW, Volkswagen, Audi, Porsche and Land Rover. </div>
      <div class="text bottom">Guanghzou BBmart is a professional automotive aftermarket supply chain management company that adheres to brand development and supervises OEM standards. With 16 years of intensive work in the industry and relying on this sound supply chain management system, Guangzhou BBmart is committed to building the first cost-effective brand in the global automotive aftermarket. Adhering to the mission of "everything is centered on customer needs, and constantly meeting customer needs", we provide customers with one-stop, full-category, cost-effective solutions.</div>
      <div class="text bottom">At present, Guangzhou BBmart's sales network has covered more than 40 countries and regions overseas, and has an in-depth understanding of the needs of car models in various countries. The product categories have exceeded 30,000+, covering suspension systems, steering systems, braking systems, transmission systems, rubber and metal System, shock absorber system, absorption system, engine cooling and heating system, engine electrical system, engine sealing system, timing system, fuel supply system, window opening and closing system and other products.</div>
      <div class="text">Guangzhou BBmart has a complete after-sales guarantee and support system:</div>
      <div class="text">1. Implement 40,000 km warranty for 2 years;</div>
      <div class="text">2. One-to-one, point-to-point exclusive customer service docking, providing efficient after-sales service;</div>
      <div class="text bottom">3. Powerful enterprise IT support, product query and model matching system, provide fast and accurate data services;</div>
      <div class="text bottom">Guangzhou BBmart has participated in internationally renowned exhibitions such as the Canton Fair, Shanghai Auto Parts Exhibition, French Auto Parts Exhibition, Frankfurt Auto Parts Exhibition in Germany and other internationally renowned exhibitions over the years. It has in-depth contacts and exchanges with customers and maintains industry insights. </div>
      <div class="text bottom">We sincerely invite you to become partners with Guangzhou BBmart and grow together.</div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import shopHead from '../../components/shop/head'
import commonFooter from '../../components/common/footer'
export default {
  components: {
    shopHead,
    commonFooter
  },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss" scoped>
.img-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  img {
    width: 49%;
    height: auto;
    display: block;
    margin: 20px 0;
  }
}
.text {
  width: 100%;
  line-height: 26px;
  font-size: 16px;
  text-align: justify;
  &.bottom {
    margin-bottom: 20px;
  }
}
</style>